import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";

const routes = [
  {
    path: "/",
    name: "index",
    //component: () => import('./pages/LandingDemo.vue')
    component: () => import("./pages/Login.vue"), // IMPOSTATO PER IL MOMENTO IL LOGIN COME INDEX
  },
  {
    path: "/admin",
    name: "admin",
    component: App,
    meta: {
      auth: { roles: [1] },
    },
    children: [
      {
        path: "dashboard",
        name: "admin.dashboard",
        component: () => import("./pages/AdminDashboard.vue"),
      },
    ],
  },
  {
    path: "/",
    name: "app",
    component: App,
    meta: {
      auth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        //component: () => import('./components/Dashboard.vue')
        component: () => import("./components/MapsDashboard.vue"),
        meta: {
          auth: { roles: [1, 2, 3] },
        },
      },
      {
        path: "/home",
        name: "home",
        component: () => import("./components/Dashboard.vue"),
        meta: {
          auth: { roles: [1, 2] },
        },
      },
      {
        path: "/report",
        name: "report",
        component: () => import("./components/Report.vue"),
        meta: {
          auth: { roles: [1, 2] },
        },
      },
      {
        path: "/account",
        name: "account",
        component: () => import("./components/Account.vue"),
        meta: {
          auth: { roles: [1, 2, 3] },
        },
      },
      {
        path: "/devices",
        name: "devices",
        component: () => import("./pages/Devices.vue"),
        meta: {
          auth: { roles: [1, 2] },
        },
      },
      {
        path: "/drivers",
        name: "drivers",
        component: () => import("./pages/Drivers.vue"),
        meta: {
          auth: { roles: [1, 2] },
        },
      },
      /*
            {
                path: '/formlayout',
                name: 'formlayout',
                component: () => import('./components/FormLayoutDemo.vue')
            },
            {
                path: '/input',
                name: 'input',
                component: () => import('./components/InputDemo.vue')
            },
            {
                path: '/floatlabel',
                name: 'floatlabel',
                component: () => import('./components/FloatLabelDemo.vue')
            },
            {
                path: '/invalidstate',
                name: 'invalidstate',
                component: () => import('./components/InvalidStateDemo.vue')
            },
            {
                path: '/button',
                name: 'button',
                component: () => import('./components/ButtonDemo.vue')
            },
            {
                path: '/table',
                name: 'table',
                component: () => import('./components/TableDemo.vue')
            },
            {
                path: '/list',
                name: 'list',
                component: () => import('./components/ListDemo.vue')
            },
            {
                path: '/tree',
                name: 'tree',
                component: () => import('./components/TreeDemo.vue')
            },
            {
                path: '/panel',
                name: 'panel',
                component: () => import('./components/PanelsDemo.vue')
            },
            {
                path: '/overlay',
                name: 'overlay',
                component: () => import('./components/OverlayDemo.vue')
            },
            {
                path: '/media',
                name: 'media',
                component: () => import('./components/MediaDemo.vue')
            },
            {
                path: '/menu',
                component: () => import('./components/MenuDemo.vue'),
                children: [
                    {
                        path: '',
                        component: () => import('./components/menu/PersonalDemo.vue')
                    },
                    {
                        path: '/menu/seat',
                        component: () => import('./components/menu/SeatDemo.vue')
                    },
                    {
                        path: '/menu/payment',
                        component: () => import('./components/menu/PaymentDemo.vue')
                    },
                    {
                        path: '/menu/confirmation',
                        component: () => import('./components/menu/ConfirmationDemo.vue')
                    },
                ],
            },
            {
                path: '/messages',
                name: 'messages',
                component: () => import('./components/MessagesDemo.vue')
            },
            {
                path: '/file',
                name: 'file',
                component: () => import('./components/FileDemo.vue')
            },
            {
                path: '/chart',
                name: 'chart',
                component: () => import('./components/ChartDemo.vue')
            },
            {
                path: '/misc',
                name: 'misc',
                component: () => import('./components/MiscDemo.vue')
            },
            {
                path: '/crud',
                name: 'crud',
                component: () => import('./pages/CrudDemo.vue')
            },
            {
                path: '/timeline',
                name: 'timeline',
                component: () => import('./pages/TimelineDemo.vue')
            },
            {
                path: '/empty',
                name: 'empty',
                component: () => import('./components/EmptyPage.vue')
            },
            {
                path: '/documentation',
                name: 'documentation',
                component: () => import('./components/Documentation.vue')
            },
            {
                path: '/blocks',
                name: 'blocks',
                component: () => import('./components/BlocksDemo.vue')
            },
            {
                path: '/icons',
                name: 'icons',
                component: () => import('./components/IconsDemo.vue')
            },*/
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/Login.vue"),
  },

  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/:allOtherPaths(.*)*", //'/notfound',
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
  {
    path: "/403",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
  {
    path: "/expired",
    name: "expired",
    component: () => import("./pages/SessionExpired.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
