<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo">
			<img alt="Logo" :src="topbarImage()" style="height: 3rem;" />
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button" v-styleclass="{
			selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein',
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true
		}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li>
				<button class="p-link layout-topbar-button" v-tooltip.bottom="'Account'" @click="toggle"
					aria-haspopup="true" aria-controls="overlay_panel">
					<i class="pi pi-user"></i>
				</button>
				<OverlayPanel ref="op" appendTo="body" id="overlay_panel">
					<div class="flex align-items-center py-2">
						<div
							class="w-3rem h-3rem flex align-items-center justify-content-center bg-orange-100 border-circle mr-3 flex-shrink-0">
							<i class="pi pi-user text-xl text-orange-500"></i>
						</div>
						<span class="text-900">{{ this.$auth.user().email }}</span>
					</div>
				</OverlayPanel>
			</li>
			<li>
				<button @click="this.$router.push({ name: 'dashboard' })" v-tooltip.bottom="'Dashboard'"
					class="p-link layout-topbar-button">
					<i class="pi pi-home"></i>
					<span>Dashboard</span>
				</button>
			</li>
			<li>
				<button v-if="this.$auth.check([1, 2])" @click="this.$router.push({ name: 'report' })"
					v-tooltip.bottom="'Report'" class="p-link layout-topbar-button">
					<i class="pi pi-book"></i>
					<span>Report</span>
				</button>
			</li>
			<li v-if="this.$auth.check([1])">
				<button @click="this.$router.push({ name: 'admin.dashboard' })" v-tooltip.bottom="'Dashboard Admin'"
					class="p-link layout-topbar-button">
					<i class="pi pi-globe"></i>
					<span>Admin</span>
				</button>
			</li>

			<!--<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li>
			<li>
				<button @click="this.$router.push({ name: 'account'})" class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-bell mr-4 p-text-secondary" v-badge="2"></i>
					
					<span>Notifiche</span>
				</button>
			</li>-->
			<li v-if="this.$auth.impersonating()">
				<button @click="this.$auth.unimpersonate({ makeRequest: false, redirect: { name: 'login' }, })"
					v-tooltip.bottom="'Ritorna admin'" class="p-link layout-topbar-button">
					<i class="pi pi-user-plus"></i>

					<span>Admin</span>
				</button>
			</li>

			<li>
				<button @click="this.$auth.logout()" v-tooltip.bottom="'Logout'" class="p-link layout-topbar-button">
					<i class="pi pi-sign-out"></i>
					<span>Logout</span>
				</button>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		onTopbarMenuToggle(event) {
			this.$emit('topbar-menu-toggle', event);
		},
		topbarImage() {
			return 'layout/images/logo-errego.png';
			// return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},

		toggle(event) {
			this.$refs.op.toggle(event);
		},
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>